import { useNotification } from '@/hooks/useNotification';
import { supabase } from '@/utils/supabase-client';
import { useUser } from '@/utils/useUser';
import { Popover, Transition } from '@headlessui/react';
import { MessageCircle } from 'lucide-react';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

export const FeedbackButton = ({
  placeholder,
  className,
  initialMessage
}: {
  placeholder?: string;
  className?: string;
  initialMessage?: string;
}) => {
  const notification = useNotification();
  const [message, setMessage] = useState<string>(initialMessage || '');
  const { user } = useUser();
  const router = useRouter();

  const onSubmit = () => {
    (async () => {
      await supabase
        .from('msh_feedback_form')
        .insert([
          {
            message: message,
            user_id: user?.id,
            page_path: router.asPath
          }
        ])
        .then((res) => {
          if (res.error) {
            notification.showError({
              title: 'Could not send feedback',
              message: res.error.message
            });
          } else {
            setMessage('');
            notification?.showNotification({
              title: 'Thank you for your feedback!',
              message: 'We will get back to you as soon as possible.'
            });
          }
        });
    })();
  };
  const [popoverPosition, setPopoverPosition] = useState({
    right: '0',
    bottom: '10'
  });

  // Callback ref to measure the popover dimensions once it's rendered
  const popoverRef = useCallback((node) => {
    if (node !== null) {
      const rect = node.getBoundingClientRect();

      if (rect.right > window.innerWidth) {
        setPopoverPosition((prev) => ({ ...prev, right: '10' }));
      }
      if (rect.bottom > window.innerHeight) {
        setPopoverPosition((prev) => ({ ...prev, bottom: '10' }));
      }
    }
  }, []);

  return (
    <Popover className="md:relative ">
      <Popover.Button
        className={
          className ||
          ' btn-default secondary  transition-colors  flex-row gap-x-2 items-center  '
        }
      >
        <MessageCircle className="" size={16} />
        Help & Feedback
      </Popover.Button>

      <Transition
        enter="transition  duration-100 ease-out"
        enterFrom="transform scale-40 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel
          className={`absolute z-50 ${popoverPosition.right} ${popoverPosition.bottom} pt-2 `}
          ref={popoverRef}
        >
          {({ close }) => (
            <div className=" w-screen md:w-96  panel-rounded border border-line bg-neutral-50 dark:bg-black p-5 shadow-2xl  ">
              <form action="#">
                <label htmlFor="comment" className="sr-only">
                  Add your comment
                </label>
                <textarea
                  rows={3}
                  name="comment"
                  autoFocus
                  id="comment"
                  onChange={(e) => setMessage(e.target.value)}
                  className="block w-full border  px-5 py-3 leading-relaxed resize-none focus:ring-0 focus:border-line text-sm panel white dark:bg-neutral-900 dark:text-primary  text-primary border-line placeholder-neutral-400 shadow-none"
                  placeholder={
                    placeholder ||
                    'Your feedback is important to us. Please let us know how we can improve. '
                  }
                  value={message}
                  // defaultValue={}
                />
              </form>
              <div className="flex flex-shrink-0 pt-5 items-end justify-end">
                <button
                  type="submit"
                  onClick={() => {
                    onSubmit();
                    close();
                  }}
                  className=" items-center px-4 py-2 border border-transparent text-sm rounded-md shadow-sm text-white bg-blue-700 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default FeedbackButton;
